import { h } from "preact";
import { useMemo, useCallback } from "preact/hooks";

export const POSITION_LEFT = "left";
export const POSITION_RIGHT = "right";

const ICON =
  "0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAElBMVEVMaXEAAAAAAAAAAAAAAAAAAAAlAuGTAAAABnRSTlMAHcT9QV7XVM/HAAAARklEQVR4AWMgFYwCRgEoiQ6EFCEkhgZlIwEoiQpYjI0VoSQaCDY2EoCSqIAVrJh1WGhBSOAyauhqQEQtgcSAmXzwJLhRAACZ1R01RqDodQAAAABJRU5ErkJggg==) no-repeat";

const Navigation = (props) => {
  const { page, pageCount, setPage, position, enabled, mouseOver } = props;
  const classNames = ["sqr-recommender-navigation", `is--${position}`];

  const changePage = useCallback(() => {
    if (position === POSITION_LEFT) {
      setPage(Math.max(0, page - 1));
    } else {
      setPage(Math.max(0, Math.min(pageCount - 1, page + 1)));
    }
  }, [page, pageCount, setPage]);

  const iconStyle = useMemo(
    () => ({
      display: "inline-block",
      background: ICON,
      backgroundSize: "1.5em 1.5em",
      backgroundPosition: "center",
      transform: position === POSITION_LEFT ? "rotate(180deg)" : undefined,
      width: "1.5em",
      height: "1.5em",
    }),
    [position]
  );

  const style = useMemo(
    () => ({
      position: "absolute",
      [position]: "-1em",
      top: "calc(50% - 1.5em)",
      width: "3em",
      height: "3em",
      lineHeight: "3em",
      border: "1px solid #dfe1e5",
      boxShadow: "0 0 0 1px rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 20%)",
      backgroundColor: "#fff",
      borderRadius: "50%",
      fontWeight: 600,
      opacity: mouseOver ? 1 : 0,
      display: enabled ? "flex" : "none",
      cursor: "pointer",
      justifyContent: "center",
      alignItems: "center",
    }),
    [enabled, mouseOver, position]
  );

  const icon = position === POSITION_LEFT ? "&#8658" : ">>";

  return (
    <div className={classNames.join(" ")} style={style} onclick={changePage}>
      <span style={iconStyle} />
    </div>
  );
};

export default Navigation;
