import { h } from "preact";
import { useLayoutEffect, useState } from "preact/hooks";

const useDimensions = (ref) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    // I don't think it can be null at this point, but better safe than sorry
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width);
      setHeight(ref.current.getBoundingClientRect().height);
    }
  });

  useState(() => {
    const listener = () => {
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();
        setWidth(width);
        setHeight(height);
      }
    };

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  });

  return {
    width,
    height,
  };
};

export default useDimensions;
