import { h, render } from "preact";
import qs from "qs";
import Slider from "./components/Slider";
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";

class App {
  constructor(config) {
    this.recommenderConfigCache = {};
    this.options = {
      apiEndpoint: "process.env.RECOMMENDER_API_URI",
      disableCss: false,
      defaultLanguage: (navigator.language || navigator.userLanguage).substring(
        0,
        2
      ),
      ...config.options,
    };

    this.init();
  }

  init() {
    if (!this.options.apiEndpoint) {
      console.error("API endpoint not set");
      return false;
    }

    setInterval(
      function () {
        // locate placeholders and fill it with the slider
        this.fillPlaceholders();
      }.bind(this),
      100
    );
  }

  async fetchRecommenderConfig(uuid) {
    if (this.recommenderConfigCache[uuid]) {
      return this.recommenderConfigCache[uuid];
    }

    try {
      const response = await fetch(
        `${this.options.apiEndpoint}/indexes/v1/recommenders/${uuid}/config`,
        {
          mode: "cors",
          headers: {
            Accept: "application/json",
          },
        }
      );
      this.recommenderConfigCache[uuid] = await response.json();

      return this.recommenderConfigCache[uuid];
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Parse product ID parameter
   * Will map query_string:<field> to the value of the <field> attribute in the query string.
   *
   * @param productId
   * @returns {null|*}
   */
  processProductId(productId) {
    if (productId === null) {
      return null;
    }

    let matches;
    if (
      (matches = productId.match(
        /^query_string:(?<field>[a-zA-Z0-9_\-:]+)$/
      )) !== null
    ) {
      const queryParams = qs.parse(window.location.search.substr(1));
      const field = matches.groups.field;
      if (
        queryParams[field] &&
        queryParams[field].match(/^[0-9a-zA-Z]+$/i) !== null
      ) {
        return queryParams[field];
      } else {
        return null;
      }
    }

    return productId;
  }

  async fillPlaceholders() {
    const nodes = document.querySelectorAll("ins[data-sqr-container-uuid]");

    for (let iNode = 0; iNode < nodes.length; iNode++) {
      const node = nodes[iNode];

      if (node.getAttribute("data-sqr-rendered")) {
        continue;
      }

      node.setAttribute("data-sqr-rendered", "true");
      const customHtmlToRender = node.innerHTML;
      const targetNode = document.querySelector(
        node.getAttribute("data-sqr-target")
      );

      // style ins element to reserve space
      if (node.style.minHeight.length === 0) {
        node.style.minHeight = "305px";
      }
      node.style.display = targetNode ? "none" : "block";
      node.innerHTML = "";

      const uuid = node.getAttribute("data-sqr-container-uuid");
      const productId = this.processProductId(
        node.getAttribute("data-sqr-product-id")
      );
      const itemsPerPage = node.hasAttribute("data-sqr-items-per-page")
        ? parseInt(node.getAttribute("data-sqr-items-per-page"), 10)
        : undefined;
      const automaticSliding = node.hasAttribute("data-sqr-automatic-sliding")
        ? node.hasAttribute("data-sqr-automatic-sliding") === "true" ||
          node.hasAttribute("data-sqr-automatic-sliding") === "1"
        : undefined;
      const slideTimeout = node.hasAttribute("data-sqr-slide-timeout")
        ? parseInt(node.getAttribute("data-sqr-slide-timeout"), 10)
        : undefined;
      const renderOutside = node.hasAttribute("data-sqr-render-outside");

      // load config
      const config = await this.fetchRecommenderConfig(uuid);
      if (config === null || config.error) {
        console.error(
          `Unable to load configuration for Sooqr Recommender ${uuid}`
        );
        return;
      }

      // load default CSS when not prevented
      if (!this.options.disableCss) {
        this.loadCss();
      }

      let requestConfig = window.sqr_recommender_config || {};

      if ('recommenders' in requestConfig) {
        if (uuid in requestConfig.recommenders) {
          requestConfig = requestConfig.recommenders[uuid];
        } else {
          requestConfig = {};
        }
      }

      const design = config.design || {};
      const filteredDesign = Object.fromEntries(
        Object.entries(design).filter(([_, v]) => v !== null && v !== undefined)
      );
      const finalConfig = {
        ...config,
        design: {
          items_per_page: itemsPerPage,
          automatic_sliding: automaticSliding,
          slide_timeout: slideTimeout,
          render_outside: renderOutside,
          ...filteredDesign,
        },
        request_body: requestConfig,
      };

      // remove undefined values
      Object.keys(finalConfig).forEach(
        (key) => finalConfig[key] === undefined && delete finalConfig[key]
      );

      // disable text decoration
      node.style.textDecoration = "none";

      render(
        <Slider
          config={finalConfig}
          productId={productId}
          options={this.options}
          customHtml={customHtmlToRender}
        />,
        targetNode || node,
        node.lastChild
      );
      node.style.minHeight = null;
    }
  }

  loadCss() {
    const scripts = document.getElementsByTagName("script");
    for (let iScript = 0; iScript < scripts.length; iScript += 1) {
      if (scripts[iScript].src.match(/\/sooqr-recommender\.js/i)) {
        const cssUrl = scripts[iScript].src.replace(
          /\/sooqr-recommender\.js/i,
          "/sooqr-recommender.css"
        );

        // check if link already exists
        const links = document.getElementsByTagName("link");
        for (let iLink = 0; iLink < links.length; iLink += 1) {
          if (links.href === cssUrl) {
            return;
          }
        }

        // add CSS to document
        const link = document.createElement("link");
        link.href = cssUrl;
        link.type = "text/css";
        link.rel = "stylesheet";
        link.media = "screen,print";

        document.getElementsByTagName("head")[0].appendChild(link);
      }
    }
  }
}

export default App;
