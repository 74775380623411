import { h } from "preact";
import { useMemo } from "preact/hooks";
import "./Pager.scss";

const bulletStyle = ({ isActive }) => ({});

const Bullet = (props) => {
  const { isActive } = props;
  const classNames = ["sqr-recommender-pager-bullet"];
  if (isActive) {
    classNames.push("is--active");
  }
  return (
    <button
      tabindex={props.page}
      type="button"
      className={classNames.join(" ")}
      data-sqr-pager-page={props.page}
      style={bulletStyle(props)}
      onclick={() => props.setPage(props.page)}
    >
      {props.page}
    </button>
  );
};

const Pager = (props) => {
  const { page, pageCount, setPage } = props;

  if (pageCount <= 1) {
    return null;
  }

  const pages = useMemo(
    () => (pageCount > 0 ? new Array(pageCount).fill(1, 0, pageCount) : []),
    [pageCount]
  );

  return (
    <div className="sqr-recommender-pager">
      {pages.map((pageItem, pageIndex) => (
        <Bullet
          page={pageIndex}
          isActive={page === pageIndex}
          setPage={setPage}
        />
      ))}
    </div>
  );
};

export default Pager;
