import { useState, useEffect } from "preact/hooks";

const useRecommenderData = (uuid, productId, options, body) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const rest = body ?? {};

    fetch(`${options.apiEndpoint}/recommendations`, {
      method: 'POST',
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        container_uuid: uuid,
        product_ids: productId ? [productId] : undefined,
        ...rest
      }),
    })
      .then((res) => res.json())
      .then((jsonData) => {
        setResponse(jsonData);
      })
      .catch((err) => {
        setError(err);
        setResponse(null);
      })
      .finally(() => setLoading(false));
  }, [uuid, productId, options, body]);

  return { response, loading, error };
};

export default useRecommenderData;
