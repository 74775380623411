import App from "./App";

let config = {};

if (window.sqrrcmnd) {
  config = window.sqrrcmnd;
}
if (typeof config.options === "undefined") {
  config = {
    options: config,
  };
}
const tool = new App(config);

export default {
  init: tool.init.bind(tool),
};
