import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import "./Image.scss";

const Image = (props) => {
  const [source, setSource] = useState(
    props.item.image || props.config.design.no_img_img
  );
  const [hasError, setHasError] = useState(false);

  const handleImageError = useCallback(() => {
    if (!hasError) {
      setHasError(true);
      if (props.config.design.no_img_img) {
        setSource(props.config.design.no_img_img);
      }
    }
  }, [hasError, props.item, props.config]);

  return (
    <img
      className="sqr-recommender-image"
      src={source}
      alt={props.item.title}
      onerror={hasError || handleImageError}
    />
  );
};

export default Image;
