import { h } from "preact";
import {useCallback, useMemo} from "preact/hooks";

const containerStyle = {
  display: "flex",
  "justify-content": "space-between",
  padding: "0 8px 8px 8px",
};

const normalPriceStyleBuilder = (design, isSame, bothPricesAvailable) => ({
  "text-decoration": bothPricesAvailable ? "line-through" : "",
  display: isSame ? "none" : "block",
  color: design.colors.old_price_color
    ? `#${design.colors.old_price_color}`
    : undefined,
});

const priceStyleBuilder = (design) => ({
  "font-weight": "bold",
  color: `#${
    design.colors.discounted_price_color || design.colors.old_price_color
  }`,
});

const Prices = (props) => {
  const { design } = props;
  const isSame = props.price === props.normalPrice;
  const isSameClass = isSame ? "is--same" : null;

  const normalPriceStyle = useMemo(
    () => normalPriceStyleBuilder(design, isSame, props.price),
    [design, isSame, props.price]
  );
  const priceStyle = useMemo(() => priceStyleBuilder(design), [design]);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(design.locale, {
        style: "currency",
        currency: design.currency,
        minimumFractionDigits: 2,
      }),
    [design.currency, design.locale]
  );

  const format = useCallback((amount) => {
    if (design.show_currency_symbol) {
      return formatter.format(amount);
    }

    return (amount).toLocaleString(design.locale, { minimumFractionDigits: 2, maximumFractionDigits: formatter.resolvedOptions().maximumFractionDigits });
  }, [design.locale, design.show_currency_symbol]);

  return (
    <div className="sqr-recommender-prices" style={containerStyle}>
      {props.normalPrice && (
        <div
          className={["sqr-recommender-normal-price", isSameClass].join(" ")}
          style={normalPriceStyle}
        >
          {format(props.normalPrice)}
        </div>
      )}
      {props.price && (
        <div
          className={["sqr-recommender-price", isSameClass].join(" ")}
          style={priceStyle}
        >
          {format(props.price)}
        </div>
      )}
    </div>
  );
};

export default Prices;
