import { h } from "preact";
import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "preact/hooks";
import useRecommenderData from "../hooks/useRecommenderData";
import useDimensions from "../hooks/useDimensions";
import Loader from "./Loader";
import Slide from "./Slide";
import Pager from "./Pager";
import Navigation, { POSITION_LEFT, POSITION_RIGHT } from "./Navigation";
import "./Slider.scss";

const DEFAULT_MINIMUM_SLIDE_WIDTH = 150;

const titleStyle = (design) => ({
  color: `#${design.colors?.header_title_color}`,
});

const Slider = (props) => {
  const { productId, options } = props;
  const baseConfig = props.config;
  const { container_uuid } = baseConfig;
  const containerRef = useRef();
  const { width } = useDimensions(containerRef);
  const [page, setPage] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const { response, loading, error } = useRecommenderData(
    container_uuid,
    productId,
    options,
    baseConfig.request_body
  );
  const [calculatedMaxItemsPerPage, setCalculatedMaxItemsPerPage] = useState(1);

  const config = useMemo(
    () => ({
      ...baseConfig,
      design: {
        minimum_slide_width: DEFAULT_MINIMUM_SLIDE_WIDTH,
        automatic_sliding: true,
        slide_timeout: 3000,
        currency: "EUR",
        locale: "nl-NL",
        show_currency_symbol: true,
        open_in_new_tab: false,
        ...baseConfig.design,
      },
    }),
    [baseConfig]
  );

  const enableSliding =
    error === null &&
    response !== null &&
    response.error === undefined &&
    config.design.automatic_sliding &&
    !mouseOver;

  const onMouseEnter = useCallback(() => {
    setMouseOver(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setMouseOver(false);
  }, []);

  // recalculate maximum number of items on width change
  useEffect(() => {
    if (width) {
      const maxItems = Math.max(
        1,
        Math.floor(width / config.design.minimum_slide_width)
      );
      setCalculatedMaxItemsPerPage(maxItems);
      if (response) {
        setPageCount(Math.ceil(response.data.length / maxItems));
      }
    }
  }, [width]);

  // enable the slider
  useEffect(() => {
    if (enableSliding) {
      const interval = setInterval(() => {
        const newPage = (page + 1) % pageCount;
        setPage(newPage);
      }, config.design.slide_timeout);
      return () => clearInterval(interval);
    }
  }, [enableSliding, pageCount, page, config.design.slide_timeout]);

  useLayoutEffect(() => {
    if (pageCount > 1) {
      containerRef.current.scrollLeft = width * page;
    }
  }, [page, width, pageCount]);

  if (loading) {
    return <Loader />;
  }

  const finalTitleStyle = useMemo(() => titleStyle(config.design), [
    config.design,
  ]);

  if (error !== null || response === null) {
    return null;
  }
  if (response.error) {
    return <div className="srq-recommender-error">{response.Error}</div>;
  }

  const itemsPerPage = Math.min(
    response.data.length,
    config.design.items_per_page || calculatedMaxItemsPerPage
  );
  const slideWidth = 100 / itemsPerPage;
  const finalSlideConfig = {
    ...config,
    slideWidth: `${slideWidth}%`,
  };
  const sliderStyle = {
    width:
      response !== null ? `${(response.data.length / itemsPerPage) * 100}%` : null,
  };

  return (
    <div
      className="srq-recommender-slider-container"
      onmouseover={onMouseEnter}
      onmouseout={onMouseLeave}
      data-sqr-recommender-uuid={container_uuid}
    >
      {config.active_recommender_name && config.show_recommender_name && (
        <h2 className="srq-recommender-slider-title" style={finalTitleStyle}>
          {config.active_recommender_name}
        </h2>
      )}
      <Navigation
        position={POSITION_LEFT}
        setPage={setPage}
        page={page}
        pageCount={pageCount}
        mouseOver={mouseOver}
        enabled={page > 0}
      />
      <Navigation
        position={POSITION_RIGHT}
        setPage={setPage}
        page={page}
        pageCount={pageCount}
        mouseOver={mouseOver}
        enabled={page + 1 < pageCount}
      />
      <div className="srq-recommender-slider-outer" ref={containerRef}>
        <div className="srq-recommender-slider" style={sliderStyle}>
          {response.data.map((item) => (
            <Slide
              item={item}
              config={finalSlideConfig}
              options={options}
              productId={productId}
              customHtml={props.customHtml}
            />
          ))}
        </div>
      </div>
      <Pager
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        design={config.design}
      />
    </div>
  );
};

export default Slider;
