import { h } from "preact";
import Prices from "./Prices";
import {useCallback, useEffect, useMemo, useState} from "preact/hooks";
import { decodeEntities } from "../utils/decodeEntities";
import Image from "./Image";
import "./Slide.scss";

const styleBuilder = (design) => ({
  width: design.slideWidth || "100%",
  maxWidth: design.maximum_slide_width || undefined,
});

const titleStyleBuilder = (design) => ({
  color: design.colors.header_title_color
    ? `#${design.colors.header_title_color}`
    : undefined,
});

const textStyleBuilder = (design) => ({
  color: design.colors.description_color
    ? `#${design.colors.description_color}`
    : undefined,
});

const Slide = (props) => {
  const { item, config, options, productId } = props;
  const { container_uuid } = config;
  const [customFields, setCustomFields] = useState([])
  const basicFields = ['detail_url', 'image', 'title', 'text_text', 'price', 'double_normal_price'];

  useEffect(function () {
    const fieldNames = Object.keys(config.fields);
    let customFields = [];

    for (let i = 0, l = fieldNames.length; i < l; i++) {
      const fieldName = fieldNames[i];
      let fieldValue = item.properties[fieldName];

      if (config.fields[fieldName] === false) {
        continue;
      }

      if (basicFields.includes(fieldName)) {
        continue;
      }

      if (Array.isArray(fieldValue)) {
        if (fieldValue.length === 0) {
          continue;
        }

        fieldValue = fieldValue[0];
      }

      if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
        continue;
      }

      customFields.push({key: fieldName, value: fieldValue});
    }

    setCustomFields(customFields);
  }, [config.fields])

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      fetch(`${options.apiEndpoint}/${container_uuid}/collect_click_data/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...item.properties,
          text_text: Array.isArray(item.properties.text_text)
            ? item.properties.text_text.join("\n")
            : item.properties.text_text,
          recommended_product_id: productId || undefined,
        }),
      }).finally(() => {
        if (config.design.open_in_new_tab) {
          window.open(item.properties.detail_url, '_blank');
        } else {
          window.location.href = item.properties.detail_url;
        }
      });
    },
    [item, productId]
  );

  const style = useMemo(() => styleBuilder(config.design), [config.design]);
  const titleStyle = useMemo(() => titleStyleBuilder(config.design), [
    config.design,
  ]);
  const textStyle = useMemo(() => textStyleBuilder(config.design), [
    config.design,
  ]);

  const renderTemplate = (template, item) => {
    return new Function(...Object.keys(item.properties), `return \`${template}\`;`)(...Object.values(item.properties));
  }

  return (
    <div
      className="sqr-recommender-slide"
      style={style}
      data-sqr-product-id={item.properties.product_id}
    >
      <a
        className="sqr-recommender-link sqr-recommender-title"
        href={item.properties.detail_url}
        onclick={handleClick}
      >
        {config.fields.image && (
          <div className="sqr-recommender-image-container">
            <Image config={config} item={item.properties} />
          </div>
        )}
        {config.fields.title && (
          <div className="sqr-recommender-title" style={titleStyle}>
            {decodeEntities(item.properties.title)}
          </div>
        )}
        {config.fields.text_text && (
          <div className="sqr-recommender-text" style={textStyle}>
            {decodeEntities(
              Array.isArray(item.properties.text_text)
                ? item.properties.text_text.join("\n")
                : item.properties.text_text
            )}
          </div>
        )}
        {customFields.map(customField => (
            <div key={customField.key} className={'sqr-recommender-' + customField.key} data-sqr-value={customField.value}>{customField.value}</div>
        ))}
        <Prices
          design={config.design}
          price={config.fields.price ? item.properties.price : null}
          normalPrice={
            config.fields.double_normal_price ? item.properties.double_normal_price : null
          }
        />
        {!config.design.render_outside ? (
          <div className={"sqr-recommender-custom-content"} dangerouslySetInnerHTML={{ __html: renderTemplate(props.customHtml, item)}}/>
        ) : null}
      </a>
      {config.design.render_outside ? (
          <div className={"sqr-recommender-custom-content"} dangerouslySetInnerHTML={{ __html: renderTemplate(props.customHtml, item)}} />
      ) : null}
    </div>
  );
};

export default Slide;